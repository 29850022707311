import { Store, applyMiddleware, combineReducers, createStore } from 'redux';

import App from './App';
import { Provider } from 'react-redux';
import authenticationReducer from './store/authentication/reducer';
import globalReducer from './store/global/reducer';
import jobsReducer from './store/jobs/reducer';
import redirectsReducer from './store/redirects/reducer';
import { render } from 'react-dom';
import thunk from 'redux-thunk';
import usersReducer from './store/users/reducer';
import tenantsReducer from "./store/tenants/reducer"
import manageReducer from "./store/manage/reducer"
import passManagementReducer from './store/passManagement/reducer';
import futureSecurityReducer from "./store/futureSecurity/reducer";

const reducers = combineReducers({
  global: globalReducer,
  authentication: authenticationReducer,
  jobs: jobsReducer,
  redirects: redirectsReducer,
  users: usersReducer,
  tenants: tenantsReducer,
  manage: manageReducer,
  passManagement: passManagementReducer,
  futureSecurity: futureSecurityReducer
});

const store: Store & {
  dispatch: DispatchType
} = createStore(reducers, applyMiddleware(thunk))

const rootElement = document.getElementById("root")
render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
)