import * as actions from './constants';
import * as cognito from '../../helpers/cognito';

import config from '../../helpers/config';
import { request } from '../../helpers/request';

export function resetAuthenticationState() {
  const action = { type: actions.RESET };
  return (dispatch: AuthDispatchType) => {
    dispatch({ ...action });
  };
}

export function signIn() {
  const action = { type: actions.SIGN_IN_WITH_EMAIL };
  return async (dispatch: AuthDispatchType) => {
    try {
      dispatch({ type: actions.SET_IS_SIGNING_IN });
      const session = await cognito.getSession();
      if (session.isValid()) {
        const groups = await cognito.getUserGroups();
        const { username } = await cognito.getCurrentUser();
        const { user } = await request('GET', `${config.apiUrl}/users/user/${username}`);
        dispatch({ type: actions.SIGN_IN_WITH_EMAIL, 
          response: { 
            username, 
            groups, 
            tenants: user.tenants, 
            locations: user.locations, 
            fulfilment: user.fulfilment, 
            passManagementTenants: Object.keys(user.passManagementTenants),
            passManagementPermissions: user.passManagementTenants,
            futureSecurityTenants: user.futureSecurityTenants,
          } 
          });
      }
    } catch (error: any) {
      dispatch({ ...action, response: {error} })
    }
  }
}

export function signOut() {
  const action = { type: actions.SIGN_OUT };

  return async (dispatch: AuthDispatchType) => {
    try {
      await cognito.signOut();
      dispatch({ ...action, response: { success: true } });
      dispatch({ type: actions.RESET})
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function signUpWithEmail(username: string, email: string, password: string) {
  const action = { type: actions.SIGN_UP_WITH_EMAIL };

  return async (dispatch: AuthDispatchType) => {
    try {
      dispatch({ type: actions.SET_IS_SIGNING_UP });
      await cognito.signUpUserWithEmail(username, email, password);
      dispatch({ ...action, response: { success: true } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function forgotPassword(username: string, code: string, password: string) {
  const action = { type: actions.FORGOT_PASSWORD };

  return async (dispatch: AuthDispatchType) => {
    try {
      dispatch({ type: actions.SET_SUBMITTING_FORGOT_PASSWORD });
      await cognito.forgotPassword(username, code, password);
      dispatch({ ...action, response: { success: true } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function changePassword(oldPassword: string, password: string) {
  const action = { type: actions.CHANGE_PASSWORD };

  return async (dispatch: AuthDispatchType) => {
    try {
      dispatch({ type: actions.SET_IS_CHANGING_PASSWORD });
      const result = await cognito.changePassword(oldPassword, password);
      if(result !== "SUCCESS") return
      const { username } = await cognito.getCurrentUser() as any;
      const groups = await cognito.getUserGroups();
      const { user } = await request('GET', `${config.apiUrl}/users/user/${username}`);
      dispatch({ ...action, response: { username, groups, tenants: user.tenants } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}


export function loadNotifications() {
  return loadNotificationsAction;
}

export const loadNotificationsAction = async (dispatch: AuthDispatchType) => {
  const action = { type: actions.LOAD_NOTIFICATIONS };
  try {
    dispatch({ type: actions.SET_IS_LOADING_NOTIFICATIONS });
    const { awaiting, rejected } = await request('GET', `${config.apiUrl}/users/notifications`);
    dispatch({ ...action, response: { awaiting, rejected } });
  } catch (error) {
    dispatch({ ...action, response: { error } });
  }
}