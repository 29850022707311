import './assets/milligram.min.css';
import './assets/font.css';
import './assets/main.css';
import './assets/styles.css';
import './assets/dark.css';
import { Amplify, Auth, Hub } from 'aws-amplify'
import config from "./helpers/config"
import { AuthStatus, isAdmin, isRecruitmentEditor, isRedirectEditor, isFulfilmentRead, isPassManagementEditor, isFutureSecurityAuthorised } from './helpers/authentication';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ChangePassword from './routes/authentication/changePassword';
import { Dispatch } from 'redux';
import Home from './routes/home';
import Jobs from './routes/jobs';
import NotFound from './routes/NotFound';
import Redirects from './routes/redirects';
import Ticker from './routes/ticker';
import Users from './routes/users';
import Manage from './routes/manage';
import Tenants from './routes/tenants';
import { signIn, signOut } from './store/authentication/actions';
import FulfilmentReports from './routes/fulfilmentReports';
import PassManagement from './routes/passManagement';
import BulkAuditTable from './routes/passManagement/bulkUploadTable';
import ShiftViolations from './routes/shiftViolations';
import DynamicThresholds from './routes/futureSecurity';

const MainRoute: React.FunctionComponent = () => {
  const { authStatus, authGroups, fulfilmentLvl, pmTenants, fsrTenants } = useSelector(
    (state: MainState) => ({
      authStatus: state.authentication.authStatus,
      authGroups: state.authentication.authGroups,
      fulfilmentLvl: state.authentication.fulfilmentAuth?.level,
      pmTenants: state.authentication.passManagementTenants,
      fsrTenants: state.authentication.futureSecurityTenants
    }),
    shallowEqual
  );

  const dispatch: Dispatch<any> = useDispatch();
  
  useEffect(() => {
    Amplify.configure(config.awsConfig)
    Auth.configure({ oauth: config.awsAuth })
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          dispatch(signIn())
          break
        case 'signOut':
          dispatch(signOut())
          break
      }
    })
  }, [])
  

  useEffect(() => {
    if (authStatus !== AuthStatus.SignedIn) {
      dispatch(signIn());
    }
  }, [dispatch, authStatus]);

  return (
    <Router>
      <Routes>
        {authStatus === AuthStatus.SignedIn &&
          <>
            {isRecruitmentEditor(authGroups) && <Route path="jobs" element={<Jobs />} />}
            {isRecruitmentEditor(authGroups) && <Route path="manage" element={<Manage />} />}
            {isRedirectEditor(authGroups) && <Route path="redirects" element={<Redirects />} />}
            {isRedirectEditor(authGroups) && <Route path="ticker" element={<Ticker />} />}
            {isAdmin(authGroups) && <Route path="users" element={<Users />} />}
            {isAdmin(authGroups) && <Route path="tenants" element={<Tenants />} />}
            {isFulfilmentRead(fulfilmentLvl) && <Route path="/fulfilment" element={<FulfilmentReports />} />}
            {isFulfilmentRead(fulfilmentLvl) && <Route path="/shift-violations" element={<ShiftViolations />} />}
            {isPassManagementEditor(pmTenants) && <Route path='/pass-management' element={<PassManagement />} />}
            {isPassManagementEditor(pmTenants) && <Route path='bulk-upload-audit' element={<BulkAuditTable />} />}
            {isFutureSecurityAuthorised(fsrTenants) && <Route path="/future-security" element={<DynamicThresholds />} />}
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="/" element={<Home />} />
          </>
        }
        {
          authStatus !== AuthStatus.SignedIn &&
          <>
            <Route path="/" element={<Home />} />
          </>
        }
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

const App: React.FC = () => {
  return (
    <MainRoute />
  );
}

export default App;