import Accordion from "../global/accordion"

import useLoadAuditRecords from "./hooks/useAuditRecords";
import { useEffect, useState } from "react";
import { request } from "../../helpers/request";
import config from "../../helpers/config";
import { Select } from "../form/validatedSelect";
import * as Joi from "joi";
import { AuthTenant, isFulfilmentManager } from "../../helpers/authentication";
import { useSelector } from "react-redux";
import { manchesterTerminalOptions } from "../../helpers/factory";


export const Extracts: React.FC = () => {
    const { fulfilmentLvl, tenant } = useSelector(
        (state: MainState) => ({
            fulfilmentLvl: state.authentication.fulfilmentAuth?.level,
            tenant: state.authentication.fulfilmentAuth?.tenant
        }),
    );
    const { noMoreResults, items, getNextPage, resultsLoading, refresh } = useLoadAuditRecords();
    const [sending, setSending] = useState(false);
    const [sendResultMessage, setSendResultMessage] = useState("")
    const [isValidDates, setIsValidDates] = useState(false)
    const [report, setReport] = useState<AuditRecord>({
        from: "",
        to: "",
        type: "Fulfilment",
        requestedDate: new Date().toString(),
        terminal: tenant === AuthTenant.ManchesterAirport ? manchesterTerminalOptions[0].value : undefined
    });
    const minDate = getMinimumDate()
    const errorMessage = (report.from && report.to && !isValidDates) ? "Dates must not be in the past, and \"from\" not before \"to\"." : undefined
    const showDeploymentOption = tenant === AuthTenant.StanstedAirport;
    const showDemandOption = tenant !== AuthTenant.ManchesterAirport;
    const showAvailabilityOption = tenant === AuthTenant.ManchesterAirport;

    function getMinimumDate() {
        const now = new Date();
        return now.toISOString().split("T")[0]
    }

    const sendReportRequest = async () => {
        setSending(true)
        if (!isFulfilmentManager(fulfilmentLvl)) {
            setSendResultMessage("Reports can only be run by those with elevated privileges. Please ask your administrator to upgrade you.")
            setSending(false)
            return
        }
        if (tenant !== AuthTenant.ManchesterAirport) {
            const pendingRequests: AuditRecord[] = await checkForPendingRequests()
            if (pendingRequests.length > 0) {
                setSendResultMessage("There are already pending requests for this airport. Please try again soon when this has processed.")
                setSending(false)
                return
            }
        }
        const endpoint = `${config.apiUrl}/fulfilment/audit-item`
        try {
            await request("POST", endpoint, { item: report })
            setSendResultMessage("Report sent!")
        } catch (error: any) {
            setSendResultMessage(`Something went wrong sending your report request. ${error?.message}`)
        }
        setSending(false)
    }

    const checkForPendingRequests = async () => {
        const endpoint = `${config.apiUrl}/fulfilment/${report.type}/audit-items/pending`;
        try {
            const pendingReqs = await request("GET", endpoint)
            return pendingReqs
        } catch (err) {
            console.log("Error checking for pending requests")
            console.log(err)
        }
    }

    useEffect(() => {
        if (report.type === "Deployment") {
            setIsValidDates(true)
            return
        }
        if (report.from >= minDate && report.to >= minDate && report.to >= report.from) {
            setIsValidDates(true)
        } else {
            setIsValidDates(false)
        }
    }, [minDate, report.from, report.to, report.type])

    const dropdownOptions = [
        {
            label: "Fulfilment",
            value: "Fulfilment"
        }
    ]

    if (showDemandOption) {
        dropdownOptions.push({
            label: "Demand",
            value: "Demand"
        })
    }

    if (showDeploymentOption) {
        dropdownOptions.push({
            label: "Deployment",
            value: "Deployment"
        })
    }

    if (showAvailabilityOption) {
        dropdownOptions.push({
            label: "Staff Availability",
            value: "Availability"
        })
    }


    const runReports = (
        <>
            <p><span className="italics">Demand</span> comes from Better Security Staff Plans. <span className="italics">Fulfilment</span> comes from colleagues' shifts in Dimensions.</p>
            <Select options={dropdownOptions} label="Extract Type" schema={Joi.any()} onChange={(v) => setReport({ ...report, type: v })} value={report.type!} />
            {report.type !== "Deployment" && (
                <>
                    <div className="date-picker">
                        <label htmlFor="fromDate">From</label>
                        <input type="date" id="fromDate" onChange={(e) => setReport({ ...report, from: e.target.value })} className="datepicker" min={minDate} />
                    </div>
                    <div className="date-picker">
                        <label htmlFor="toDate">To</label>
                        <input type="date" id="toDate" onChange={(e) => setReport({ ...report, to: e.target.value })} className="datepicker" min={minDate} />
                    </div>
                </>
            )}
            {report.type === "Availability" && tenant === AuthTenant.ManchesterAirport && (
                <Select options={manchesterTerminalOptions} label="Terminals" schema={Joi.any()} onChange={(v) => setReport({ ...report, terminal: v })} value={report.terminal!} />
            )}
            <p className="error-message">{errorMessage && errorMessage}</p>
            <p>{sendResultMessage}</p>
            <button className="button" disabled={!isValidDates} onClick={sendReportRequest}>{sending ? "Sending..." : "Refresh Extract"}</button>
        </>
    )

    const loadMoreResults = () => {
        const lastCurrentResult = items[items.length - 1]
        getNextPage(lastCurrentResult?.id!)
    }

    const sortedItems = [...items].sort((a, b) => b.requestedDate.localeCompare(a.requestedDate))

    const reportAuditTrail = (
        <>
            <button style={{ width: "100%" }} onClick={() => refresh()}>Get Latest</button>
            <table className="react-table">
                <thead>
                    <tr>
                        <th>Extract Type</th>
                        <th>Airport</th>
                        <th>Requested By</th>
                        <th>Requested On</th>
                        <th>Dates Processed</th>
                        <th>Status</th>
                        <th>Request Id</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedItems.map((record: AuditRecord) => (
                        <tr key={record.id}>
                            <td>{record.type}</td>
                            <td>{record.airport}</td>
                            <td className="wfm-broken-cells">{record.requestedBy}</td>
                            <td className="wfm-broken-cells">{record.requestedDate || ""}</td>
                            <td className="wfm-broken-cells">{record.from} - {record.to}</td>
                            <td>{record.status}</td>
                            <td className="wfm-broken-cells">
                                {record.id}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {!noMoreResults && <button className="button" onClick={loadMoreResults}>{resultsLoading ? "Loading..." : "Load More Results"}</button>}
        </>)

    return (
        <main>
            <h1>Demand & Fulfilment Extracts</h1>
            <Accordion data={[
                {
                    title: "Refresh Extracts",
                    content: runReports
                },
                {
                    title: "Extract Refresh Audit Trail",
                    content: reportAuditTrail
                }
            ]}
                header={""} />
        </main>)
}