import React, { useState } from "react";
import { Input } from "../form/validatedInput";
import Joi from "joi";

type EditTickerMessageProps = {
  message?: TickerMessage,
  onSave: (message: TickerMessage) => void,
}

export const EditTickerMessage: React.FC<EditTickerMessageProps> = ({
  message,
  onSave
}) => {

  const [text, setText] = useState<string>(message?.text || '')
  const [url, setUrl] = useState<string>(message?.url || '')
  const [heading, setHeading] = useState<string>(message?.heading || '')

  return (
    <div className="modal-form">
      <Input
        type='text'
        label="Text"
        value={text}
        schema={Joi.any()}
        onChange={(value) => setText(value)}
        touchedOverride={undefined}
      ></Input>
      <Input
        type='text'
        label="Url"
        value={url}
        schema={Joi.required()}
        onChange={(value) => setUrl(value)}
        touchedOverride={undefined}
      ></Input>
      <Input
        type='text'
        label="Header"
        value={heading}
        schema={Joi.required()}
        onChange={(value) => setHeading(value)}
        touchedOverride={undefined}
      ></Input>

      <button onClick={() => onSave({
        heading,
        text,
        url,
        color: "",
        background: ""
      })}>Save</button>
    </div>
  )
}