import { useEffect, useState } from 'react';
import { request } from '../../../helpers/request';
import config from '../../../helpers/config';

const makeViolation = async () => {
        const item = {
          airport: "MAN",
          shiftDate: "09-12-24",
          violations: [
            "Working loads mate",
            "Too long",
            "Not enough time between shifts"
          ],
        }

        await request("POST", `${config.apiUrl}/better-shift/violation`, item)
}

const useLoadAuditRecords = () => {
  const [items, setItems] = useState<ShiftViolations[]>([]);
  const [noMoreResults, setNoMoreResults] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const fetchItems = async () => {
    if(noMoreResults) return
    setLoading(true)
    try {
      const records: ShiftViolations[] = await request("GET", `${config.apiUrl}/better-shift/violations`);
      if(records.length) {
        setItems(records);
      } else {
        setNoMoreResults(true)
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const refresh = () => {
    setItems([])
    fetchItems()
  }

  return { noMoreResults, items, resultsLoading: loading, refresh, makeViolation };
};

export default useLoadAuditRecords;
