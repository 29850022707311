import { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux";
import { AuthTenant } from "../../../helpers/authentication";
import { Dispatch } from "redux";
import { getTerminals, loadMetrics } from "../../../store/futureSecurity/actions";
import config from '../../../helpers/config';
import { request } from "../../../helpers/request";


export const useLoadMetrics = (dispatch: Dispatch<any>) => {
  const { username, locations } = useSelector(
    (state: MainState) => ({
      locations: state.authentication.futureSecurityTenants,
      username: state.authentication.username,
      terminals: state.futureSecurity.terminals
    }),
    shallowEqual
  );

  const [location, setLocation] = useState(locations[0]);

  useEffect(() => {
    if (location) {
      dispatch(loadMetrics(location));
    }
  }, [dispatch, location]);

  useEffect(() => {
    setLocation(locations[0]);
  }, [locations]);

  useEffect(() => {
    console.log("Location has changed, should be dispatching GT")
    dispatch(getTerminals(location))
  }, [location])


  return { location, setLocation }
}