import NavigationBar from '../components/navigation/navigationBar';
import ShiftViolationsTable from '../components/fulfilment/shiftViolations';

const ShiftViolations: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
        <ShiftViolationsTable />
      </section>
    </div>
  );
}

export default ShiftViolations