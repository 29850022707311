import { ApprovalStatus } from '../store/jobs/schema';
import { AuthTenant } from './authentication';
import HttpStatusCode from './httpStatusCode';

export const statusText = (approvalStatus: ApprovalStatus) => {
  switch (approvalStatus) {
    case ApprovalStatus.AwaitingApproval: return 'Awaiting';
    case ApprovalStatus.Approved: return 'Approved';
    case ApprovalStatus.Rejected: return 'Rejected';
    case ApprovalStatus.Expired: return 'Expired';
    case undefined: return 'n/a';
    default: throw new Error(`Unexpected approval status ${approvalStatus}`);
  }
}

export const locationText = (tenant: AuthTenant) => {
  switch (tenant) {
    case AuthTenant.ManchesterAirport: return 'Manchester Airport';
    case AuthTenant.EastMidlandsAirport: return 'East Midlands Airport';
    case AuthTenant.StanstedAirport: return 'London Stansted Airport';
    case undefined: return 'n/a';
    default: throw new Error(`Unexpected tenant ${tenant}`);
  }
}

export const enumOptions = (e: any, includeNone: boolean): SelectOption[] => {
  const options = Object.values(e).map((v) => ({ value: v, label: (v as any).toString() }));
  if (includeNone) {
    options.unshift({ value: undefined, label: 'None' });
  }
  return options;
}

export const defaultUser = (): User => ({
  username: '', email: '', phoneNumber: '', authGroups: [], tenants: [], locations: ['MAN'],
  fsrTenants: []
});

export const ukPhonePrefix = '+44';

export const defaultJob = (): Job => ({ title: '', id: '', approvalStatus: ApprovalStatus.AwaitingApproval, logo: '' });

export const defaultPass = (): Pass => ({ employeename: '', passid: undefined, role: '', employeeid: undefined});

export const defaultRedirect = (): Redirect => ({ key: '', uri: '', status: 301 });

export const defaultTenant = (): Tenant => ({
  name: "Company Name", 
  contact: "contact@email.com", 
  description: "Brand description",
  subTenants: [],
  location: AuthTenant.ManchesterAirport,
  logoTitles: [],
})

export const defaultMetricDefinition = (): MetricDefinition => ({
  name: "",
  code: "",
  comparator: "", 
  laneType: "",
  groupType: "",
  results: [""],
  airport: AuthTenant.ManchesterAirport
})

export const statusCodeOptions = [
  { value: HttpStatusCode.MOVED_PERMANENTLY, label: '301: Moved Permenantly' }
];

export const manchesterTerminalOptions = [{label: "T1", value: "1"}, {label: "T2", value: "2"}, {label: "T3", value: "3"}, {label: "All", value: "all"}]