import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as Joi from "joi";

// import { ManageMetricDetails } from './manageMetric';
import { Dispatch } from 'redux'
import Loader from '../global/loader';
import { LocationSelect } from '../form/locationSelect';
// import { useCloseConfirmAfterLoad } from './hooks/useCloseConfirmAfterLoad';
// import { useCloseCreateAfterLoad } from './hooks/useCloseCreateAfterLoad';
// import { useCloseEditAfterLoad } from './hooks/useCloseEditAfterLoad';
// import { useLoadMetrices } from './hooks/useLoadMetrices';
// import { useCloseExcelUploadAfterLoad } from './hooks/useCloseExcelUploadAfterLoad';
import { ConfirmModal } from '../global/confirmModal';
import { AuthTenant, } from '../../helpers/authentication';
import { Link } from 'react-router-dom';
import { useLoadMetrics } from './hooks/useLoadsMetrics';
import { Select } from '../form/validatedSelect';
import { loadMetricDefinitions } from '../../store/futureSecurity/actions';
import { defaultMetricDefinition } from '../../helpers/factory';
import { MultiSelect } from '../form/validatedMultiSelect';

export const FSRThresholds: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { location, setLocation } = useLoadMetrics(dispatch);
  const { terminals, metrics, lanes, cells, isLoadingTerminals, isLoadingMetricDefinitions, isLoadingMetricValues } = useSelector(
    (state: MainState) => ({
      terminals: state.futureSecurity.terminals,
      metrics: state.futureSecurity.metrics,
      lanes: state.futureSecurity.lanes,
      cells: state.futureSecurity.cells,
      isLoadingMetricDefinitions: state.futureSecurity.isLoadingMetricDefinitions,
      isLoadingMetricValues: state.futureSecurity.isLoadingMetricValues,
      isLoadingTerminals: state.futureSecurity.isLoadingTerminals,
    }),
    shallowEqual
  );
  const [terminal, setTerminal] = useState<string>(terminals[0] || "")
  const [metric, setMetric] = useState(metrics[0])
  const [applyTo, setApplyTo] = useState<{ type: string, values: string[] }>()

  useEffect(() => { console.log(terminals, cells, lanes) }, [terminals, cells, lanes, metric, metrics])

  useEffect(() => {
    dispatch(loadMetricDefinitions(location))
  }, [location, terminal])

  useEffect(() => {
    console.log("Metrics: ", metrics)
    setMetric(metrics[0])
  }, [metrics])

  const getLaneOptions = useMemo(() => {
    let res: { key: string; label: string; }[] | { key: string; value: string; }[] = []
    if (metric?.groupType === "cell") {
      const filteredCells = cells.filter(
        (cell) => {
          return cell.airport === location && cell.groupType === terminal
        }
      );
      res = filteredCells.map((cell) => ({ key: cell.name, label: cell.name }));
    } else if (metric?.groupType === "lane") {
      console.log("YEYA: ", location, terminal, cells)
      const lane = lanes.find(
        (lane) => lane.name === terminal && lane.airport === location
      );
      console.log("lane is: ", lane)
      res = lane ? lane.results.map((lane) => ({ key: lane, value: lane })) : [];
    }
    console.log("Res in glo: ", res)
    return res;
  }, [metric, location, terminal, cells, lanes])

  const onChangeMetric = (value: string) => {
    const selectedMetric = metrics.find((m) => m.name === value);
    if (selectedMetric) {
      setMetric(selectedMetric);
    }
  }




  return (
    <main>
      <h1>Metric Management</h1>
      <LocationSelect value={location} onChange={setLocation} optionsProp='futureSecurityTenants' />
      {isLoadingTerminals ? <Loader isLoading={true} /> : terminals.length > 1 &&
        <Select
          schema={Joi.string()}
          value={terminal}
          onChange={setTerminal}
          label="Terminal"
          options={terminals.map((term) => ({ label: `${term.replace("T", "Terminal ")}`, value: term }))}
        />}
      {isLoadingMetricDefinitions ? <Loader isLoading={true} /> : <><Select
        schema={Joi.string()}
        value={metric?.name ?? ""}
        onChange={onChangeMetric}
        label="Metric"
        options={metrics?.map((metric: MetricDefinition) => ({ label: metric.name, value: metric.name }))}
      />
        <MultiSelect
          options={getLaneOptions}
          label="Apply to (Lanes/ Cells)"
          onChange={setApplyTo}
          schema={Joi.any()}
          selectedOptions={applyTo?.values || []}
        />
      </>
      }
    </main>
  );
}

