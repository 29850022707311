import Loader from "../global/loader";
import useShiftViolationsRecords from './hooks/useShiftViolationsRecords';


const ShiftViolationsTable: React.FC = () => {
    const { items, refresh, resultsLoading } = useShiftViolationsRecords()

    const formatDateStr = (dateStr: string) => {
        const date = new Date(dateStr);
        const options = { timeZone: 'Europe/London', hour12: false };
        const formattedDate = date.toLocaleString('en-GB', options);
        return formattedDate
    }



    return (
        <main>
            <h1>Shift Violations (Last 10 records)</h1>
            <button style={{width:"100%"}} onClick={refresh}>Refresh</button>
            {resultsLoading ? <Loader isLoading={true} /> :
                <table className="react-table">
                    <thead>
                        <tr>
                            <th>Airport</th>
                            <th>Shift(s) Date</th>
                            <th>Violations</th>
                            <th>Timestamp</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((record: ShiftViolations, i: number) => (
                            <tr key={i}>
                                <td>{record.airport}</td>
                                <td>{record.shiftDate}</td>
                                <td>
                                    <ul>{record.violations?.map((violation) => <li>{violation}</li>)}</ul>
                                </td>
                                <td className="wfm-broken-cells">{formatDateStr(record.timestamp)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
        </main>
    )

}

export default ShiftViolationsTable;