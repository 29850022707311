export const SET_LOADING_TERMINALS = "METRICS_SET_LOADING_TERMINALS"
export const LOAD_TERMINALS = "METRICS_LOAD_TERMINALS"
export const SET_LOADING_METRICS = 'METRICS_SET_LOADING_METRIC_VALUES';
export const LOAD_METRICS = 'METRICS_LOAD_METRIC_VALUES';
export const SET_LOADING_METRIC_DEFINITIONS = 'METRICS_SET_LOADING_METRIC_DEFINITIONS';
export const LOAD_METRIC_DEFINITIONS = 'METRICS_LOAD_METRIC_DEFINITIONS';
export const SET_CREATING_METRIC = 'METRICS_SET_CREATING_METRIC';
export const CREATE_METRIC = 'METRICS_CREATE_METRIC';
export const SET_UPDATING_METRIC = 'METRICS_SET_UPDATING_METRIC';
export const UPDATE_METRIC = 'METRICS_UPDATE_METRIC';
export const SET_DELETING_METRIC = 'METRICS_SET_DELETING_METRIC';
export const DELETE_METRIC = 'METRICS_DELETE_METRIC';
export const SET_UPDATING_METRIC_APPROVAL_STATUS = 'METRICS_SET_UPDATING_METRIC_APPROVAL_STATUS';
export const UPDATE_METRIC_APPROVAL_STATUS = 'METRICS_UPDATE_METRIC_APPROVAL_STATUS';
export const RESET = 'METRICS_RESET';
