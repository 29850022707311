import * as actions from './constants';

const initialState: FutureSecurityState = {
  isLoadingTerminals: false,
  isLoadingMetricDefinitions: false,
  isLoadingMetricValues: false,
  error: undefined,
  terminals: [],
  metrics: [],
  cells: [],
  lanes: []
}

const reducer = (
  state: FutureSecurityState = initialState,
  action: MetricsAction
  ): FutureSecurityState => {
  switch (action.type) {
    case actions.SET_LOADING_METRICS:
      return { ...state, isLoadingMetricValues: true, error: undefined }
    case actions.LOAD_METRICS:
      if (action.response.error) {
        return { ...state, isLoadingMetricValues: false, error: action.response.error };
      }
      return {
        ...state,
        isLoadingMetricValues: false,
        metrics: action.response.metrics
      }
      case actions.SET_LOADING_METRIC_DEFINITIONS:
        return { ...state, isLoadingMetricDefinitions: true, error: undefined }
      case actions.LOAD_METRIC_DEFINITIONS:
        console.log("CELLS LANES ETC UIBSJNGKER ", action.response)
        if (action.response.error) {
          return { ...state, isLoadingMetricDefinitions: false, error: action.response.error };
        }
        return {
          ...state,
          isLoadingMetricDefinitions: false,
          metrics: action.response.metrics,
          cells: action.response.cells,
          lanes: action.response.lanes
        }
    case actions.LOAD_TERMINALS:
      if(action.response.error) {
        return { ...state, isLoadingTerminals: false, error: action.response.error }
      }
      return {
        ...state, 
        isLoadingTerminals: false,
        terminals: action.response.terminals,
        error: undefined
      }
    case actions.SET_LOADING_TERMINALS:
      return {
        ...state,
        isLoadingTerminals: true
      }
    default: return state;
  }
}

export default reducer