import * as actions from './constants';

import { AuthStatus } from '../../helpers/authentication';

const initialState: AuthState = {
  isSigningIn: false,
  isSigningUp: false,
  isSendingCode: false,
  isVerifyingCode: false,
  isChangingPassword: false,
  isResettingPassword: false,
  authStatus: AuthStatus.SignedOut,
  requiresPasswordReset: false,
  newPasswordChallengeCompleted: false,
  authGroups: [],
  tenants: [],
  locations: [],
  sessionInfo: {},
  futureSecurityTenants: [],
  isLoadingNotifications: false,
  notifications: {
    awaiting: 0,
    rejected: 0
  },
};

const reducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case actions.SIGN_IN_WITH_LOCAL_STORAGE:
      if (action.response.error) {
        return { ...state, isSigningIn: false, signInError: action.response.error };
      }
      return {
        ...state,
        isSigningIn: false,
        requiresPasswordReset: false,
        authStatus: AuthStatus.SignedIn,
        username: action.response.username,
        authGroups: action.response.groups,
        tenants: action.response.tenants,
        locations: action.response.locations
      };
    case actions.SET_IS_SIGNING_IN:
      return { ...state, isSigningIn: true, signInError: undefined };
    case actions.SET_REQUIRES_PASSWORD_RESET:
      return { ...state, requiresPasswordReset: true, isSigningIn: false, signInError: undefined };
    case actions.SIGN_IN_WITH_EMAIL:
      if (action.response.error) {
        return { ...state, isSigningIn: false, signInError: action.response.error };
      }
      return {
        ...state,
        isSigningIn: false,
        signInError: undefined,
        authStatus: AuthStatus.SignedIn,
        username: action.response.username,
        authGroups: action.response.groups,
        tenants: action.response.tenants,
        locations: action.response.locations,
        fulfilmentAuth: action.response.fulfilment,
        passManagementTenants: action.response.passManagementTenants,
        passManagementPermissions: action.response.passManagementPermissions,
        futureSecurityTenants: action.response.futureSecurityTenants
      };
    case actions.SIGN_OUT:
      return initialState;
    case actions.SET_IS_SIGNING_UP:
      return { ...state, isSigningUp: true, signUpError: undefined };
    case actions.SIGN_UP_WITH_EMAIL:
      return { ...state, isSigningUp: false, signUpError: action.response.error };
    case actions.SET_IS_VERIFYING_CODE:
      return { ...state, isVerifyingCode: false, verifyCodeError: undefined };
    case actions.VERIFY_CODE:
      return { ...state, isVerifyingCode: false, verifyCodeError: action.response.error };
    case actions.SET_SUBMITTING_FORGOT_PASSWORD:
      return { ...state, isResettingPassword: true, resetPasswordError: undefined };
    case actions.FORGOT_PASSWORD:
      return { ...state, isResettingPassword: false, resetPasswordError: action.response.error };
    case actions.SET_IS_CHANGING_PASSWORD:
      return { ...state, isChangingPassword: true, changePasswordError: undefined };
    case actions.CHANGE_PASSWORD:
      return { ...state, isChangingPassword: false, changePasswordError: action.response.error };
    case actions.COMPLETE_NEW_PASSWORD_CHALLENGE:
      if (action.response.error) {
        return { ...state, isSigningIn: false, signInError: action.response.error };
      }
      return {
        ...state,
        isSigningIn: false,
        requiresPasswordReset: false,
        newPasswordChallengeCompleted: true
      };
    case actions.SET_IS_SENDING_CODE:
      return { ...state, isSendingCode: true, sendCodeError: undefined }
    case actions.SEND_CODE:
      return { ...state, isSendingCode: false, sendCodeError: action.response.error };
    case actions.SET_IS_LOADING_NOTIFICATIONS:
      return { ...state, isLoadingNotifications: true, loadNotificationError: undefined };
    case actions.LOAD_NOTIFICATIONS:
      if (action.response.error) {
        return { ...state, isLoadingNotifications: false, loadNotificationError: action.response.error };
      }
      return {
        ...state,
        isLoadingNotifications: false,
        notifications: { awaiting: action.response.awaiting, rejected: action.response.rejected }
      };
    case actions.RESET:
      return initialState;
    default:
      return state;
  }
}

export default reducer;