import * as actions from './constants';

import { ApprovalStatus } from './schema';
import { AuthTenant } from '../../helpers/authentication';
import config from '../../helpers/config';
import { loadNotificationsAction } from '../authentication/actions';
import { request } from '../../helpers/request';
export function resetMetricsState() {
  return (dispatch: MetricsDispatchType) => {
    dispatch({ type: actions.RESET });
  }
}

export function loadMetrics(location: AuthTenant) {
  const action: MetricsAction = { type: actions.LOAD_METRICS };

  return async (dispatch: MetricsDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_METRICS });
      const data = await request('GET', `${config.apiUrl}/future-security?location=${location}`);
      dispatch({ ...action, response: { jobs: data } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function getTerminals(location: AuthTenant) {
  const action: MetricsAction = { type: actions.LOAD_TERMINALS }
  return async (dispatch: MetricsDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_TERMINALS });
      console.log("GETTING terminals")
      const data = await request("GET", `${config.apiUrl}/future-security/terminals/${location}`);
      console.log(data)
      dispatch({ ...action, response: { terminals: data } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function loadMetricDefinitions(location: AuthTenant) {
  const action: MetricsAction = { type: actions.LOAD_METRIC_DEFINITIONS }
  return async (dispatch: MetricsDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_METRIC_DEFINITIONS })
      const { metrics, lanes, cells} = await request("GET", `${config.apiUrl}/future-security/metrics/definitions/${location}`)
      console.log("METRICS< CELLS AND LANES: ", metrics, cells, lanes)
      dispatch({ ...action, response: { metrics, cells, lanes }})
    } catch(error) {
      dispatch({ ...action, response: { error } });
    }
  }
}


// export function createMetric(job: Metric) {
//   const action: MetricsAction = { type: actions.CREATE_METRIC };

//   const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;

//   if (job.url && emailRegex.test(job.url) && !job.url.startsWith('mailto:')) {
//     job.url = `mailto:${job.url}`
//   }

//   return async (dispatch: MetricsDispatchType) => {
//     try {
//       dispatch({ type: actions.SET_CREATING_METRIC });
//       const body = { job };
//       delete job.id;
//       const data = await request('POST', `${config.apiUrl}/recruitment?location=${job.tenant}`, body);
//       dispatch({ ...action, response: { job: data.job } });
//       loadNotificationsAction(dispatch);
//     } catch (error) {
//       dispatch({ ...action, response: { error } });
//     }
//   }
// }

// export function updateMetric(tenant: AuthTenant, job: Metric) {
//   const action: MetricsAction = { type: actions.UPDATE_METRIC };
//   return async (dispatch: MetricsDispatchType) => {
//     try {
//       if ([ApprovalStatus.AwaitingApproval, ApprovalStatus.Rejected].includes(job.approvalStatus)) {
//         dispatch({ type: actions.SET_DELETING_METRIC })
//         dispatch({ type: actions.DELETE_METRIC, response: { job: job } })
//       }

//       dispatch({ type: actions.SET_UPDATING_METRIC });
//       const body = { job };
//       const data = await request('PUT', `${config.apiUrl}/recruitment?location=${tenant}`, body);
//       if ((data.job as Metric).id === job.id || job.id?.includes(EDITED)) {
//         dispatch({ ...action, response: { job: data.job } });
//       } else {
//         dispatch({ type: actions.CREATE_METRIC, response: { job: data.job } });
//       }
//       dispatch({ type: actions.EDITED })
//       loadNotificationsAction(dispatch);
//     } catch (error) {
//       dispatch({ ...action, response: { error } });
//     }
//   }
// }

export function deleteMetric(tenant: AuthTenant, job: MetricDefinition) {
  const action: MetricsAction = { type: actions.DELETE_METRIC };

  return async (dispatch: MetricsDispatchType) => {
    try {
      dispatch({ type: actions.SET_DELETING_METRIC });
      await request('DELETE', `${config.apiUrl}/recruitment/${job.code}?location=${tenant}`);
      dispatch({ ...action, response: { job } });
      loadNotificationsAction(dispatch);
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}